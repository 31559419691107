<template>
  <o-table
    :loading="loading"
    :items="liveCategories"
    :draggable="!loading"
    :checkable="true"
    :checked-rows="selectedLiveCategories"
    @check="$emit('update:selectedLiveCategories', $event)"
    @drop="
      (droppedOnRowIndex, _, row) =>
        $emit('updatePosition', { liveCategory: row, position: droppedOnRowIndex })
    "
  >
    <b-table-column v-slot="{ row }" field="accessType" label="Accès">
      <b-icon v-if="row.accessType === 'public'" size="is-small" type="is-success" icon="unlock" />
      <b-icon
        v-else-if="row.accessType === 'private'"
        size="is-small"
        type="is-danger"
        icon="lock"
      />
    </b-table-column>
    <b-table-column v-slot="{ row }" field="name" label="Nom">
      {{ row.name }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="icon" label="Icône">
      <o-icon :name="row.icon" />
    </b-table-column>
    <b-table-column v-slot="{ row }" field="color" label="Couleur">
      {{ row.color }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="useCheckpoint" label="Validation de CP">
      <b-icon
        size="is-medium"
        :type="row.useCheckpoint ? 'is-success' : 'is-danger'"
        :icon="row.useCheckpoint ? 'check' : 'times'"
      />
    </b-table-column>
    <b-table-column v-slot="{ row }">
      <div class="buttons are-small is-right">
        <b-button
          type="is-primary"
          tag="router-link"
          :to="{
            name: 'live.edit.category.edit',
            params: { liveCategoryId: row.id },
          }"
        >
          <b-icon icon="edit" />
        </b-button>
        <b-button type="is-danger" @click="$emit('delete', row)">
          <b-icon icon="trash" />
        </b-button>
      </div>
    </b-table-column>
  </o-table>
</template>

<script>
import OTable from '@components/Table.vue'

export default {
  name: 'LiveCategoriesTable',

  components: {
    OTable,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
    selectedLiveCategories: {
      type: Array,
      required: true,
    },
  },
}
</script>
