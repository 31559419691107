<template>
  <div class="box">
    <h2 class="title is-6">Modification des configurations</h2>
    <o-table
      :items="trackers"
      :loading="loading"
      :draggable="false"
      :checkable="true"
      :checked-rows="checkedQueclinkTrackerConfigs"
      @check="checkedQueclinkTrackerConfigs = $event"
    >
      <b-table-column v-slot="{ row }" field="number" label="Balise" sortable>
        <router-link
          :to="{
            name: 'queclinkTracker.edit',
            params: { id: row.id },
          }"
        >
          {{ row.number }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="vehicleNumber" label="Dossard" sortable>
        {{ row.vehicleNumber }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        field="config.interval"
        label="Intervalle en seconde"
        sortable
      >
        {{ row.config.interval ?? '–' }}
      </b-table-column>
      <b-table-column v-slot="{ row }" field="config.isSynchronized" label="Synchronisé" sortable>
        <b-icon
          size="is-small"
          :type="row.config.isSynchronized ? 'is-success' : 'is-danger'"
          :icon="row.config.isSynchronized ? 'check' : 'times'"
        />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="config.syncStatus" label="Status" sortable>
        {{ getStatus(row.config.syncStatus) }}
      </b-table-column>
    </o-table>
    <edit-queclink-tracker-config-form
      v-if="checkedQueclinkTrackerConfigs.length"
      :loading="loading"
      class="mt-2"
      @submit="submitAll"
    />
  </div>
</template>

<script>
import OTable from '@components/Table.vue'
import { QUECLINK_TRACKER_TYPE } from '@constants/tracker/type'
import EditQueclinkTrackerConfigForm from './EditQueclinkTrackerConfigForm.vue'
import { mapActions } from 'vuex'
import { QUECLINK_TRACKER_CONFIG_SYNC_STATUS } from '@constants/queclinkTrackerConfig/syncStatus'

export default {
  name: 'ListQueclinkTrackerConfigs',

  components: {
    OTable,
    EditQueclinkTrackerConfigForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      queclinkTrackerConfigs: {},
      checkedQueclinkTrackerConfigs: [],
    }
  },

  async mounted() {
    await this.load()
  },

  computed: {
    trackers() {
      const trackers = []
      for (const vehicle of this.liveVehicles) {
        if (vehicle.trackers.length === 0) {
          continue
        }

        for (const tracker of vehicle.trackers) {
          if (tracker.type !== QUECLINK_TRACKER_TYPE) {
            continue
          }

          trackers.push({
            ...tracker,
            vehicleNumber: vehicle.number,
            config: this.queclinkTrackerConfigs[tracker.id] || {
              id: tracker.id,
              interval: null,
              isSynchronized: false,
              syncStatus: null,
            },
          })
        }
      }

      return trackers
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.loading = true

      this.queclinkTrackerConfigs = (
        await this.$services.queclinkTrackerConfigService.getAllByLiveId(this.live.id)
      ).reduce((configs, config) => ({ ...configs, [config.id]: config }), {})

      this.loading = false
    },

    async submitAll(fields) {
      this.loading = true

      const queclinkTrackerConfigs = this.checkedQueclinkTrackerConfigs.map(
        (queclinkTrackerConfig) => ({ ...queclinkTrackerConfig, ...fields }),
      )

      try {
        const updatedQueclinkTrackerConfigs = (
          await this.$services.queclinkTrackerConfigService.updateAll(queclinkTrackerConfigs)
        ).reduce((configs, config) => ({ ...configs, [config.id]: config }), {})

        this.queclinkTrackerConfigs = {
          ...this.queclinkTrackerConfigs,
          ...updatedQueclinkTrackerConfigs,
        }

        this.checkedQueclinkTrackerConfigs = []

        this.addToastMessage({
          text: 'Configurations mises à jour',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    getStatus(status) {
      const syncStatus = QUECLINK_TRACKER_CONFIG_SYNC_STATUS[status]

      return syncStatus ?? 'Aucune configuration'
    },
  },
}
</script>
