<template>
  <div class="columns is-multiline">
    <b-field
      class="column is-half is-full-mobile"
      label="Intervalle en seconde"
      :message="fields.interval.error"
      :type="fields.interval.error !== null ? 'is-danger' : ''"
    >
      <b-numberinput
        v-model="fields.interval.value"
        expanded
        controls-alignment="right"
        controls-position="compact"
        :loading="loading"
        :min="1"
      />
    </b-field>
    <b-field class="column is-12">
      <b-button type="is-primary" :loading="loading" :disabled="disabledSubmit" @click="submit">
        Modifier la sélection
      </b-button>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'EditQueclinkTrackerConfigForm',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        interval: {
          value: 60,
          error: null,
        },
      },
    }
  },

  watch: {
    'fields.interval.value'(value) {
      this.fields.interval.error =
        Number(value) >= 1 ? null : "L'intervalle doit être supérieur ou égal à 1 seconde"
    },
  },

  computed: {
    disabledSubmit() {
      return this.loading || this.fields.interval.error !== null
    },
  },

  methods: {
    submit() {
      this.$emit('submit', { interval: this.fields.interval.value })
    },
  },
}
</script>
