<template>
  <div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button tag="router-link" type="is-success" :to="{ name: 'live.edit.stage.add' }">
            <b-icon icon="plus" size="is-small" />
            <span>Nouveau</span>
          </b-button>
        </p>
      </div>
    </div>
    <div class="box is-relative">
      <b-loading :is-full-page="false" :active="loading" />
      <b-table :data="liveStages" striped hoverable>
        <b-table-column v-slot="{ row }" field="accessType" label="Accès">
          <b-icon
            v-if="row.accessType === 'public'"
            size="is-small"
            type="is-success"
            icon="unlock"
          />
          <b-icon
            v-else-if="row.accessType === 'private'"
            size="is-small"
            type="is-danger"
            icon="lock"
          />
        </b-table-column>
        <b-table-column v-slot="{ row }" field="name" label="Nom">
          {{ row.name }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="startedAt" :label="`Date de début (${utcOffset})`">
          {{ localeToZonedTime(row.startedAt) }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="endedAt" :label="`Date de fin (${utcOffset})`">
          {{ localeToZonedTime(row.endedAt) }}
        </b-table-column>
        <b-table-column v-slot="{ row }" label="">
          <div class="buttons are-small is-right">
            <b-button
              type="is-primary"
              tag="router-link"
              :to="{
                name: 'live.edit.stage.edit',
                params: { liveStageId: row.id },
              }"
            >
              <b-icon icon="edit" />
            </b-button>
            <b-button type="is-danger" @click="openConfirmModal(row)">
              <b-icon icon="trash" />
            </b-button>
          </div>
        </b-table-column>
      </b-table>
      <div v-show="!loading && errorMessage" class="has-text-grey has-text-centered">
        {{ errorMessage }}
      </div>
      <b-modal :active="liveStageSelected !== null" has-modal-card @close="closeConfirmModal">
        <div class="modal-card">
          <div class="modal-card-head">
            <p class="modal-card-title">
              Suppression de "{{ liveStageSelected && liveStageSelected.name }}"
            </p>
          </div>
          <div class="modal-card-body">
            <p>
              La suppression d'une étape est <strong>définitive</strong>.<br />
              Cela
              <strong>
                inclut la suppression du parcours, des waypoints et des zones de vitesse
              </strong>
            </p>
          </div>
          <div class="modal-card-foot">
            <button class="button" type="button" @click="closeConfirmModal">Annuler</button>
            <button class="button is-danger" type="button" @click="confirmConfirmModal">
              Supprimer
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'

export default {
  name: 'EditLiveStagesTab.vue',
  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      errorMessage: null,
      liveStages: [],
      liveStageSelected: null,
    }
  },

  async mounted() {
    await this.fetch()
  },

  computed: {
    utcOffset() {
      const offset = getTimezoneOffset(this.live.timezone) / 1000 / 60 / 60
      return `UTC${offset >= 0 ? '+' : ''}${offset}`
    },
  },

  methods: {
    async fetch() {
      this.errorMessage = null
      this.loading = true

      try {
        this.liveStages = await this.$services.liveStageService.getAll(this.live.id)
      } catch (err) {
        this.errorMessage = 'Une erreur interne est survenue.'
        console.error(err)
      }

      if (!this.errorMessage && this.liveStages.length === 0) {
        this.errorMessage = 'Aucune étape'
      }

      this.loading = false
    },

    openConfirmModal(liveStageSelected) {
      this.liveStageSelected = liveStageSelected
    },

    closeConfirmModal() {
      this.liveStageSelected = null
    },

    async confirmConfirmModal() {
      await this.$services.liveStageService.delete(this.liveStageSelected.id)
      this.closeConfirmModal()
      await this.fetch()
    },

    localeToZonedTime(date) {
      if (!(date instanceof Date)) {
        return null
      }

      const zonedDate = utcToZonedTime(date, this.live.timezone)

      return format(zonedDate, 'dd/MM/yyyy HH:mm')
    },
  },
}
</script>
