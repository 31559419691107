<template>
  <div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button tag="router-link" type="is-success" :to="{ name: 'live.edit.category.add' }">
            <b-icon icon="plus" size="is-small" />
            <span>Nouveau</span>
          </b-button>
        </p>
      </div>
    </div>
    <div class="box columns is-multiline">
      <live-categories-table
        class="column is-full"
        :loading="loading"
        :live-categories="liveCategories"
        :selected-live-categories.sync="selectedLiveCategories"
        @updatePosition="updatePosition"
        @delete="openModal($event)"
      />
      <live-category-form
        v-if="selectedLiveCategories.length"
        class="column is-full"
        :loading="loading"
        @submit="updateAll"
      />
      <o-modal
        :title="modalTitle"
        :isOpen="!!deleteLiveCategory"
        @closeModal="closeModal"
        @confirmModalAction="deleteLive"
      >
        <template>
          La suppression d'une catégorie est <strong>définitive</strong>.<br />
          Cela <strong>inclut la suppression de ses véhicules</strong>.
        </template>
      </o-modal>
    </div>
  </div>
</template>

<script>
import LiveCategoriesTable from './LiveCategoriesTable.vue'
import LiveCategoryForm from './LiveCategoryForm.vue'
import OModal from '@components/Modal.vue'
import { mapActions } from 'vuex'

export default {
  name: 'EditLiveCategoriesTab',

  components: {
    LiveCategoriesTable,
    LiveCategoryForm,
    OModal,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveCategories: [],
      selectedLiveCategories: [],
      deleteLiveCategory: null,
    }
  },

  async mounted() {
    await this.fetch()
  },

  computed: {
    modalTitle() {
      return `Suppression de la catégorie ${
        this.deleteLiveCategory && this.deleteLiveCategory.name
      }`
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch() {
      this.loading = true

      try {
        this.liveCategories = await this.$services.liveCategoryService.getAll(this.live.id)
      } catch (err) {
        console.error(err)
      }

      this.loading = false
    },

    async updatePosition({ liveCategory, position }) {
      this.loading = true

      try {
        await this.$services.liveCategoryService.update(liveCategory.id, {
          ...liveCategory,
          position,
        })

        this.addToastMessage({
          text: `La catégorie "${liveCategory.name}" a été modifiée.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.fetch()
    },

    async updateAll(fields) {
      this.loading = true

      try {
        const liveCategories = this.selectedLiveCategories.map((liveCategory) => ({
          ...liveCategory,
          ...fields,
        }))

        await this.$services.liveCategoryService.updateAll(liveCategories)

        this.selectedLiveCategories = []

        this.addToastMessage({
          text: `Les catégories ont été modifiées.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.fetch()
    },

    async deleteLive() {
      this.loading = true

      try {
        const deletedLiveCategory = await this.$services.liveCategoryService.delete(
          this.deleteLiveCategory.id,
        )

        this.liveCategories = this.liveCategories.filter(
          (category) => category.id !== deletedLiveCategory.id,
        )

        this.addToastMessage({
          text: `La catégorie "${deletedLiveCategory.name}" a été supprimée.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    openModal(liveCategory) {
      this.deleteLiveCategory = liveCategory
    },

    closeModal() {
      this.deleteLiveCategory = null
    },
  },
}
</script>
