export const LIVE_CATEGORY_ICONS = [
  'walking',
  'hiking',
  'bicycle',
  'motorcycle',
  'quad',
  'ssv',
  'car',
  'truck-pickup',
  'truck',
  'ambulance',
  'user',
  'user-doctor',
  'user-slash',
  'broom',
  'camera',
  'face-sad-tear',
  'plane',
  'sailboat',
  'helicopter',
]
