<template>
  <div>
    <b-field grouped group-multiline label="Champs à modifier">
      <p v-for="field in Object.values(this.fields)" :key="field.label" class="control">
        <b-button
          :type="field.show ? 'is-primary is-light' : ''"
          :label="field.label"
          @click="field.show = !field.show"
        >
        </b-button>
      </p>
    </b-field>
    <hr v-show="Object.values(this.fields).find((field) => field.show)" />
    <form @submit.prevent="submit">
      <b-field v-if="fields.useCheckpoint.show" label="Validation de CP">
        <b-field>
          <b-radio-button v-model="fields.useCheckpoint.value" :native-value="true">
            <span>Oui</span>
          </b-radio-button>
          <b-radio-button v-model="fields.useCheckpoint.value" :native-value="false">
            <span>Non</span>
          </b-radio-button>
        </b-field>
      </b-field>
      <b-field v-if="fields.accessType.show" label="Type d'accès">
        <b-field>
          <b-radio-button v-model="fields.accessType.value" native-value="public">
            <span>Public</span>
          </b-radio-button>
          <b-radio-button v-model="fields.accessType.value" native-value="private">
            <span>Privé</span>
          </b-radio-button>
        </b-field>
      </b-field>
      <b-field v-if="fields.icon.show" label="Icône">
        <b-field grouped group-multiline>
          <b-radio-button
            v-for="icon in liveCategoryIcons"
            :key="icon"
            v-model="fields.icon.value"
            :native-value="icon"
          >
            <span>
              <o-icon :name="icon" />
            </span>
          </b-radio-button>
        </b-field>
      </b-field>
      <b-field v-if="fields.color.show" label="Couleur">
        <b-field grouped group-multiline>
          <b-radio-button
            v-for="color in liveCategoryColors"
            :key="color.value"
            v-model="fields.color.value"
            :native-value="color.value"
          >
            {{ color.label }}
          </b-radio-button>
        </b-field>
      </b-field>
      <hr />
      <b-field class="mt-4">
        <b-button type="is-primary" :loading="loading" :disabled="disabledSubmit" @click="submit">
          Modifier la sélection
        </b-button>
      </b-field>
    </form>
  </div>
</template>

<script>
import { LIVE_CATEGORY_COLORS } from '@constants/liveCategory/color'
import { LIVE_CATEGORY_ICONS } from '@constants/liveCategory/icon'

export default {
  name: 'LiveCategoryForm',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      updates: [],
      fields: {
        accessType: {
          label: "Type d'accès",
          show: false,
          value: 'public',
        },
        useCheckpoint: {
          label: 'Validation de CP',
          show: false,
          value: true,
        },
        icon: {
          label: 'Icône',
          show: false,
          value: LIVE_CATEGORY_ICONS[0],
        },
        color: {
          label: 'Couleur',
          show: false,
          value: LIVE_CATEGORY_COLORS[0].value,
        },
      },
    }
  },

  created() {
    this.liveCategoryIcons = LIVE_CATEGORY_ICONS
    this.liveCategoryColors = LIVE_CATEGORY_COLORS
  },

  computed: {
    disabledSubmit() {
      return this.loading || !Object.values(this.fields).find((field) => field.show)
    },
  },

  methods: {
    submit() {
      const fieldsEnabled = Object.keys(this.fields).reduce((fields, key) => {
        if (this.fields[key].show) {
          return { ...fields, [key]: this.fields[key].value }
        }

        return fields
      }, {})

      this.$emit('submit', fieldsEnabled)
    },
  },
}
</script>
